import React from 'react';
import {Typography} from "@material-ui/core";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    containerStyle = {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center'
    }

    style = {
        position: 'relative',
        textAlign: 'center',
    }

    render() {
        if (this.state.hasError) {
            return (
            <div style={this.containerStyle}>
                <div style={this.style}>
                    <Typography>Sorry, something went wrong.</Typography>
                    <Typography><small>{new Date().toISOString()}</small></Typography>
                </div>
            </div>
            )
        }

        return this.props.children;
    }
}
