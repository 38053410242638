import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {Route, Routes, useLocation} from 'react-router-dom';
import {theme} from "./theme";
import ActivityDetails from "./components/learningLibrary/ActivityDetails";
import HomePage from "./components/HomePage";
import Loading from "./components/util/Loading";
import {fetchLTIJwt, getDecodedGlobalLoginJwt, getDecodedJwt, isValidJwt} from "./utils/practeraApi";
import FailedLogin from "./components/errorHandling/FailedLogin";
import NotFound from "./components/errorHandling/NotFound";
import Registration from "./components/registration/Registration";

const LOGIN_URL=process.env.REACT_APP_LOGIN_URL;

const initializeIntercom = () => {
    Promise.all([getDecodedGlobalLoginJwt(), getDecodedJwt()]).then(([decodedPublicJwt, decodedJwt]) => {
        window.Intercom("boot", {
            app_id: "ju24vncd",
            user_id: decodedJwt.user_uuid,
            email: decodedPublicJwt.username
        });
    })
}

export default function App() {

    const location = useLocation()

    const [isLoggingIn, setIsLoggingIn] = useState(true)
    const [isFetchingLtiJwt, setIsFetchingLtiJwt] = useState(false)
    const [isFailedLogin, setIsFailedLogin] = useState(false)

    const [isRegistering, setIsRegistering] = useState(false)

    useEffect(() => {
        window.Intercom("update");
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        if (params.get('do') === 'registration') {
            setIsRegistering(true);
        } else {
            if (params.get('jwt')) {
                localStorage.setItem('token', params.get('jwt'))
                initializeIntercom()
                window.location = window.origin + location.pathname
            }

            if (!isFetchingLtiJwt && !isFailedLogin) {
                if (params.get('apikey')) {
                    localStorage.setItem('apikey', params.get('apikey'))
                    setIsFetchingLtiJwt(true)
                    fetchLTIJwt(params.get('apikey')).then(data => {
                        localStorage.setItem('token', data.jwt)
                        initializeIntercom()
                        window.location = window.origin + location.pathname
                    }).catch((err) => {
                        console.error("Error with login", err)
                        setIsFailedLogin(true)
                    }).finally(() => {
                        setIsFetchingLtiJwt(false)
                    })
                } else if (localStorage.getItem('token')) {
                    isValidJwt(localStorage.getItem('token')).then(isValid => {
                        if (!isValid) {
                            window.location = LOGIN_URL
                        }
                        initializeIntercom()
                        setIsLoggingIn(false)
                    })
                } else {
                    window.location = LOGIN_URL
                }
            }
        }
    }, [isFailedLogin, isFetchingLtiJwt, location.pathname, location.search])

    if (isRegistering) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Registration setIsRegistering={setIsRegistering} />
            </ThemeProvider>
        )
    }

    if (isFailedLogin) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <FailedLogin />
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {isLoggingIn || isFetchingLtiJwt ? <Loading/> : <AppRoutes/>}
        </ThemeProvider>
    );
}

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomePage basePath={""} showLogo={true} appBarTitle={"My Journey"} context={"myActivities"}/>} />
            <Route path="/library" element={<HomePage basePath={""} showLogo={true} appBarTitle={"Library"} context={"library"}/>} />
            <Route path="/my-skills" element={<HomePage basePath={""} showLogo={true} appBarTitle={"My Skills"} context={"my-skills"}/>} />
            <Route path="/terms" element={<HomePage basePath={""} showLogo={true} appBarTitle={"Terms and Conditions"} context={"terms"}/>} />
            <Route path="/my-profile" element={<HomePage basePath={""} showLogo={true} appBarTitle={"My Profile"} context={"my-profile"}/>} />
            <Route path="/events" element={<HomePage basePath={""} showLogo={true} appBarTitle={"Events"} context={"events"}/>} />
            <Route path="/activity/:timelineUuid/:activityId" element={<ActivityDetails/>} />
            <Route path="/*" element={<NotFound/>} />

            {/*<Route path="/teams" element={<ThemeProvider theme={teamsTheme}><CssBaseline /><HomePage basePath={"/teams"} showLogo={false} appBarTitle={"My Activities"} context={"myActivities"}/></ThemeProvider>} />*/}
            {/*<Route path="/teams/library" element={<ThemeProvider theme={teamsTheme}><CssBaseline /><HomePage basePath={"/teams"} showLogo={false} appBarTitle={"Library"} context={"library"}/></ThemeProvider>} />*/}
            {/*<Route path="/embedded" element={<MyActivities/>} />*/}
            {/*<Route path="/embedded/library" element={<Library/>} />*/}
        </Routes>
    )
}
