import {Divider, Paper, Typography} from "@material-ui/core";
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import JourneyStep from "./JourneyStep";

const useStyles = makeStyles((theme) => ({
    paperClass: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
        borderRadius: 15,
        margin: 20
    },
    backgroundContainer: {
        height: 100,
        color: '#ffffff'
    },
    titleContainer: {
        position: 'absolute',
        left: 20,
        top: 10
    },
    activityContainer: {
        background: '#ffffff',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15
    },
    typography: {
        padding: theme.spacing(2),
    }
}))


export default function JourneyCard({step, user}) {

    const classes = useStyles();

    function renderActivities(activities) {
        return activities.map((activity, index) => {
            return (
                <React.Fragment key={activity.title}>
                    <JourneyStep item={activity} user={user}/>
                    { index + 1 < activities.length ? <Divider/> : ''}
                </React.Fragment>
            )
        })
    }

    return (
        <Paper elevation={3} className={classes.paperClass} style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.75)), url(' + step.imageUrl + ')'}}>
            <div className={classes.backgroundContainer} >
                <div className={classes.titleContainer}>
                    <Typography variant='body1'>{step.subtitle}</Typography>
                    <Typography variant='h6'>{step.title}</Typography>
                </div>
            </div>
            <div className={classes.activityContainer}>
                {renderActivities(step.activities)}
            </div>
        </Paper>
    )
}
