import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {LinearProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%'
        // maxHeight: 100
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '2 0 auto',
    },
    cover: {
        width: 100,
        height: 100,
        margin: 'auto'
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    progress: {
        height: 10,
        borderRadius: 10,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: 16,
    },
    typography: {
        padding: theme.spacing(2),
    },
    badge: {
        height: '100%',
        width: '100%'
    }
}));

export default function AchievementCard({achievement}) {
    const classes = useStyles();

    let grayscale = 0;

    if (achievement.progress === 0) {
        grayscale = 1
    } else if (achievement.progress * 100 < 100){
        grayscale = 0.6
    }

    return (
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
            <Card className={classes.root}>
                <Grid container>
                    <Grid item xs={2} style={{filter: 'grayscale(' + grayscale + ')'}}>
                        <div className={classes.badge} style={{ background: "url(" + achievement.badge + ")", backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                    </Grid>
                    <Grid item xs={10}>
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                <Typography variant="subtitle1">{achievement.name}</Typography>
                                <Typography variant="subtitle1" color="textSecondary">{achievement.description}</Typography>
                            </CardContent>
                            <div>
                                <LinearProgress className={classes.progress} variant="determinate" value={achievement.progress * 100} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}
