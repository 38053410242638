import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import logo from '../static/Practera_Logo_Brandmark_Color_RGB.png'
import Library from "./learningLibrary/Library";
import {Link} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from '@material-ui/icons/Menu';
import Grid from "@material-ui/core/Grid";
import Calendar from "./events/Calendar";
import JourneyContainer from "./journey/JourneyContainer";
import MySkills from "./mySkills/MySkills";
import Terms from "./terms/Terms";
import MyProfile from "./myProfile/MyProfile";
import {Divider, ListItemIcon} from "@material-ui/core";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import EventIcon from '@material-ui/icons/Event';
import Events from "./events/Events";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 20
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        backgroundColor: theme.custom.drawerColor
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.custom.drawerColor
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        minHeight: "100vh"
    },
    logo: {
        height: 32,
        marginLeft: 32,
        marginTop: 32,
        marginBottom: 16
    },
    activeListItem: {
        paddingLeft:27,
        borderLeft: "5px solid " + theme.palette.primary.main
    },
    listItem: {
        paddingLeft:32,
        color: "#8089B2",
    },
    banner: {
        position:'fixed',
        width: '100%',
        zIndex: 1201,
        textAlign:'center',
        backgroundColor: 'lavender',
        height: 40,
        fontWeight: 700,
        lineHeight: '40px'
    }
}));

function HomePage(props) {
    const { window, context, showLogo, basePath, appBarTitle } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [calendarOpen, setCalendarOpen] = React.useState(true);

    const handleDrawerToggle = () => {
        console.log('ON CLOSE')
        setMobileOpen(!mobileOpen);
    };

    const renderContext = (context) => {
        switch (context) {
            case 'myActivities':
                return <JourneyContainer setCalendarOpen={setCalendarOpen}/>
            case 'library':
                return <Library/>
            case 'my-skills':
                return <MySkills/>
            case 'terms':
                return <Terms/>
            case 'my-profile':
                return <MyProfile/>
            case 'events':
                return <Events/>
            default:
                return <Library/>
        }
    }

    function openIntercom(e) {
        e.view.Intercom('show')
    }

    const drawer = (
        <div>
            {showLogo ? (
                <div className={classes.toolbar} style={{paddingTop: 20}}>
                    <img src={logo} alt={"logo"} className={classes.logo}/>
                </div>
            ) : ''}
            <List>
                <ListItem button component={Link} to={basePath + "/"} key={"myActivities"} onClick={() => setCalendarOpen(true)} className={context === "myActivities" ? classes.activeListItem : classes.listItem}>
                    <ListItemIcon>
                        <DirectionsRunIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"My Journey"} />
                </ListItem>
                <ListItem button component={Link} to={basePath + "/library"} key={"library"} onClick={() => setCalendarOpen(true)} className={context === "library" ? classes.activeListItem : classes.listItem}>
                    <ListItemIcon>
                        <AccountBalanceIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Learning Library"} />
                </ListItem>
                <ListItem button component={Link} to={basePath + "/my-skills"} key={"my-skills"} onClick={() => setCalendarOpen(true)} className={context === "my-skills" ? classes.activeListItem : classes.listItem}>
                    <ListItemIcon>
                        <AssignmentTurnedInIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"My Skills"} />
                </ListItem>
                <ListItem button component={Link} to={basePath + "/events"} key={"events"} onClick={() => setCalendarOpen(false)} className={context === "events" ? classes.activeListItem : classes.listItem}>
                    <ListItemIcon>
                        <EventIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Events"} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button component={Link} to={basePath + "/my-profile"} key={"my-profile"} onClick={() => setCalendarOpen(false)} className={context === "my-profile" ? classes.activeListItem : classes.listItem}>
                    <ListItemIcon>
                        <AccountCircleIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"My Profile"} />
                </ListItem>
                <ListItem button component={Link} to={basePath + "/terms"} key={"terms"} onClick={() => setCalendarOpen(true)} className={context === "terms" ? classes.activeListItem : classes.listItem}>
                    <ListItemIcon>
                        <InfoIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Privacy Policy and Terms of Use"} />
                </ListItem>
                <ListItem button onClick={(e) => openIntercom(e)} key={"help"} className={classes.listItem}>
                    <ListItemIcon>
                        <QuestionAnswerIcon/>
                    </ListItemIcon>
                    <ListItemText primary={"Help"} />
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <Hidden smUp implementation="css">
                <AppBar position="fixed" className={classes.appBar} >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            {appBarTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            { calendarOpen ? <Grid container>
                <Grid item sm={9}>
                    <main className={classes.content}>
                        {renderContext(context)}
                    </main>
                </Grid>
                <Grid item sm={3}>
                    <Calendar/>
                </Grid>
            </Grid> :
                <Grid container>
                    <Grid item xs={12}>
                        <main className={classes.content}>
                            {renderContext(context)}
                        </main>
                    </Grid>
                </Grid>
            }
            <Hidden smDown>
                <div className={classes.banner} style={{top: 0}}>Do you have any questions or need help? <Link to={'#'} component="button" onClick={(e) => openIntercom(e)}>Chat with us</Link> or send us an email to <a href="mailto:employ@practera.com">employ@practera.com</a></div>
                <div className={classes.banner} style={{bottom: 0}}>Do you want to know what data we collect and see our privacy policy? <Link to={basePath + "/terms"}>Click here</Link> to learn more.</div>
            </Hidden>
        </div>
    );
}

export default HomePage;
