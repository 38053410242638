import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Button} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {bookEvent} from "../../utils/practeraApi";
import Chip from "@material-ui/core/Chip";
import LoadingSmall from "../util/LoadingSmall";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 20
    },
    registeredChip: {
        backgroundColor: 'green',
        color: 'white'
    },
    loadingContainer: {
        height: 36,
        width: 100
    }
}));

export default function CalendarEvent({event}) {
    const classes = useStyles();

    const [eventStart, setEventStart] = useState(new Date());
    const [eventEnd, setEventEnd] = useState(new Date());
    const [isBooking, setIsBooking] = useState(false);
    const [isBooked, setIsBooked] = useState(false);

    useEffect(() => {
        setEventStart(new Date(event.eventStart.replace(/ /g,"T")))
        setEventEnd(new Date(event.eventEnd.replace(/ /g,"T")))
        setIsBooked(eventStart.isBooked)
    }, [event, eventStart.isBooked])

    const handleClick = () => {
        setIsBooking(true)
        bookEvent(event.id).then((response) => {
            setIsBooking(false)
            setIsBooked(true)
        }).catch(error => {
            console.error(error)
            setIsBooking(false)
        })
    };

    function renderButton() {
        if (isBooking) {
            return <div className={classes.loadingContainer}><LoadingSmall/></div>
        }
        if (!isBooked) {
            return <React.Fragment><Button variant={'outlined'} color={'primary'} href={event.location} target={'_blank'} referrerPolicy={'no-referrer'}>Launch</Button> <Chip className={classes.registeredChip} label={'Registered'} size={'small'}></Chip></React.Fragment>
        } else {
            return <Button variant='outlined' onClick={handleClick}>Register</Button>
        }
    }

    function renderDate(date) {
        return date.toDateString();
    }

    function renderTime(date) {
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    }

    return (
        <Grid className={classes.container} container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="subtitle1"><strong>{renderDate(eventStart)}</strong></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">{event.name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1" color="textSecondary">{event.description}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">{renderTime(eventStart)} - {renderTime(eventEnd)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <div>
                    {renderButton()}
                </div>
            </Grid>
        </Grid>
    );
}
