import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import PauseIcon from '@material-ui/icons/Pause';

const useStyles = makeStyles({
    root: {
        width: 270,
        minHeight: 354,
    },
    media: {
        height: 146,
    },
    header: {
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden"
    },
    body: {
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden"
    },
    footer: {
        position: "absolute",
        left: 15,
        top: 320
    },
    triangleCompleted: {
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "0 55px 55px 0",
        borderColor: "transparent #2dcc19 transparent transparent",
        position: "absolute",
        right: 0,
        top: 0
    },
    triangleInProgress: {
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "0 55px 55px 0",
        borderColor: "transparent #f9de1b transparent transparent",
        position: "absolute",
        right: 0,
        top: 0
    },
    statusIcon: {
        position: "absolute",
        right: "3px",
        top: "3px",
        color: '#fff'
    }
});

const DEFAULT_DURATION = 5;

export const ACTIVITY_STATUS = {
    NOT_STARTED: 'not_started',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed'
}

export default function ActivityCard({activity, status}) {
    const classes = useStyles();

    const calculateDuration = (tasks) => {
        let duration = DEFAULT_DURATION
        if (tasks && Array.isArray(tasks) && tasks.length) {
            duration = tasks.reduce((accumulator, task) => {
                if (task.type === 'assessment') {
                    return accumulator + 5
                }   else {
                    return accumulator + 2
                }
            }, 0)
        }
        return duration
    }

    const renderStatus = (status) => {
        switch (status) {
            case ACTIVITY_STATUS.NOT_STARTED:
                return ''
            case ACTIVITY_STATUS.IN_PROGRESS:
                return (
                    <React.Fragment>
                        <div className={classes.triangleInProgress}></div>
                        <div className={classes.statusIcon}>
                            <PauseIcon/>
                        </div>
                    </React.Fragment>
                )
            case ACTIVITY_STATUS.COMPLETED:
                return (
                    <React.Fragment>
                        <div className={classes.triangleCompleted}></div>
                        <div className={classes.statusIcon}>
                            <CheckIcon/>
                        </div>
                    </React.Fragment>
                )
            default:
                return ''
        }
    }

    return (
        <Card className={classes.root}>
            <CardActionArea component={Link}  to={'/activity/'+ activity.timelineUuid + '/' + activity.id}>
                <CardMedia
                    className={classes.media}
                    image={activity.leadImage ? activity.leadImage : 'https://source.unsplash.com/1600x900/?business&sig=' + activity.id}
                    title={activity.name}
                />
                <CardContent>
                    <Typography className={classes.header} gutterBottom variant="h5" component="h2">
                        {activity.name}
                    </Typography>
                    <Typography className={classes.body} variant="body2" color="textSecondary" component="p" dangerouslySetInnerHTML={{__html:activity.description}}>
                    </Typography>
                    <Typography className={classes.footer} variant="body2" component="p">
                         <AccessTimeIcon style={{verticalAlign:'bottom'}}/> {calculateDuration(activity.tasks)}min
                    </Typography>
                </CardContent>
                {renderStatus(status)}
            </CardActionArea>
        </Card>
    );
}
