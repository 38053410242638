import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Divider, Hidden} from "@material-ui/core";
import {fetchEvents} from "../../utils/practeraApi";
import Loading from "../util/Loading";
import CalendarEvent from "./CalendarEvent";
import ErrorComponent from "../errorHandling/ErrorComponent";

export default function Events() {

    const [isFetching, setIsFetching] = useState(true)
    const [events, setEvents] = useState([])
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        setIsFetching(true)
        fetchEvents().then((eventsResponse) => {
            setEvents(eventsResponse.events)
            setIsFetching(false)
        }).catch(error => {
            setIsError(true)
            setIsFetching(false)
        })
    },[])

    let eventCards = ''

    if (events && Array.isArray(events)) {
        eventCards = events.map((event) => {
            return (
                <React.Fragment key={event.id}>
                    <Grid item xs={12}>
                        <CalendarEvent event={event}/>
                        <Divider/>
                    </Grid>
                </React.Fragment>
            )
        })
    }

    if (eventCards.length === 0) {
        eventCards = <Typography variant="h6">No events</Typography>
    }

    const renderError = (isError) => {
        if (isError) {
            return (
               <ErrorComponent message="An error occurred while loading the events"/>
            )
        } else {
            return ''
        }
    }

    const renderLoading = (isLoading) => {
        if (isLoading) {
            return (
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Loading/>
                </Grid>
            )
        } else {
            return ''
        }
    }

    return (
        <Container>
            <Hidden smDown>
                {renderLoading(isFetching)}
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    {renderError(isError)}
                    {!isFetching ?
                        <React.Fragment>
                            {eventCards}
                        </React.Fragment>
                        : ''}
                </Grid>
            </Hidden>

            <Hidden mdUp>
                <div style={{marginTop: 40}}>
                    {renderLoading(isFetching)}
                    <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                        {renderError(isError)}
                        {!isFetching ?
                            <React.Fragment>
                                {eventCards}
                            </React.Fragment>
                            : ''}
                    </Grid>
                </div>
            </Hidden>

        </Container>
    );
}
