import React, {useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import {Hidden} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Loading from "../util/Loading";
import {fetchAchievements} from "../../utils/practeraApi";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AchievementCard from "./AchievementCard";
import ErrorComponent from "../errorHandling/ErrorComponent";

const useStyles = makeStyles((theme) => ({
    skillChips: {
        margin: '5px',
        color: '#ffffff',
        textOverflow: 'ellipsis',
        maxWidth: 300
    },
    container: {
        marginTop: 20
    },
    typography: {
        padding: theme.spacing(2),
    }
}));

export default function MySkills() {

    const classes = useStyles();

    // const [response, setResponse] = useState(null)
    // const [survey, setSurvey] = useState(null)
    // const [userTotal, setUserTotal] = useState(0)
    // const [overallTotal, setOverallTotal] = useState(0)
    // const [highlightedSkills] = useState([]);
    // const [activities, setActivities] = useState([])

    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [achievements, setAchievements] = useState([])



    // useEffect(() => {
    //     Promise.all([queryActivities(), skillpacaApi.fetchParticipantResponse(), skillpacaApi.fetchSurvey()])
    //         .then(([payload, response, survey]) => {
    //             if (payload && payload.activities && Array.isArray(payload.activities)) {
    //                 setActivities(payload.activities.filter(activity => activity.progress === 100))
    //             }
    //             setResponse(response)
    //             setSurvey(survey)
    //             setIsLoading(false)
    //             console.log('response', response)
    //             console.log('survey', survey)
    //     })
    // }, [])
    //
    // useEffect(() => {
    //     let tempUserTotal = 0;
    //     let tempOverallTotal = 0;
    //     if (response) {
    //         Object.entries(response.skillGroups).forEach(([key, skillGroup]) => {
    //             Object.entries(skillGroup).forEach(([key, skill]) => {
    //                 if (skill.isSelected) {
    //                     tempUserTotal++
    //
    //                     if (highlightedSkills.length < 3) {
    //
    //                         highlightedSkills.push(skill.title)
    //                     }
    //
    //                 }
    //                 tempOverallTotal++
    //             })
    //         })
    //     }
    //     setUserTotal(tempUserTotal)
    //     setOverallTotal(tempOverallTotal)
    // }, [highlightedSkills, response])

    // const renderActivities = (activities) => {
    //     if (activities && Array.isArray(activities) && activities.length > 0) {
    //         return activities.map((activity) => {
    //             return (
    //                 <Grid key={activity.id} item xl={3}>
    //                     <ActivityCard activity={activity} status={ACTIVITY_STATUS.COMPLETED}/>
    //                 </Grid>
    //             )
    //         })
    //     } else {
    //         return (
    //             <Grid item>
    //                 <Paper elevation={3}><Typography className={classes.typography} variant='body1'>Check back here after you have completed some activities!</Typography></Paper>
    //             </Grid>
    //         )
    //     }
    // }

    // const renderHighlightedSkillChips = (highlightedSkills) => {
    //     return highlightedSkills.map(skillTitle => {
    //         return (
    //             <Chip key={skillTitle}
    //                   icon={<SchoolIcon/>}
    //                   label={skillTitle}
    //                   className={classes.skillChips}
    //                   color="primary"
    //             />
    //         )
    //     })
    // }

    useEffect(() => {
        setIsError(false)
        setIsLoading(true)
        fetchAchievements().then((achievementsResponse) => {
            setAchievements(achievementsResponse.achievements);
            setIsLoading(false)
        }).catch((error) => {
            setIsError(true)
            setIsLoading(false)
        })
    }, [])

    const renderLoading = (isLoading) => {
        if (isLoading) {
            return (
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Loading/>
                </Grid>
            )
        } else {
            return ''
        }
    }

    return (

        <Container className={classes.container}>
            <Hidden xsDown implementation="css">
                <Typography gutterBottom variant="h4" component="h2">
                    My Skills
                </Typography>
            </Hidden>
                {renderLoading(isLoading)}
                {isError ? <ErrorComponent message='An error occurred while loading your skills!'/> : ''}
                {!isLoading ?
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={3}>
                            {achievements.map((achievement) => {
                                return (
                                    <Grid key={achievement.id} item xs={12}>
                                         <AchievementCard achievement={achievement}/>
                                    </Grid>)
                            })}
                    </Grid>
                    :''}

        </Container>
    );
}
