import React, {useEffect, useState} from 'react';
import {fetchActivity, fetchMagicLink, fetchUser} from "../../utils/practeraApi";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {useParams} from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import SchoolIcon from '@material-ui/icons/School';
import Loading from "../util/Loading";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackButton from "../util/BackButton";
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
    heroImage: {
        height: '400px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
    },
    heroText: {
        // textAlign: 'center',
        position: 'absolute',
        width: '40%',
        top: '60%',
        left: '55%',
        transform: 'translate(-60%, -55%)',
        color: '#ffffff'
    },
    skillChips: {
        margin: '5px',
        color: '#ffffff'
    },
    startButton: {
        marginRight: '20px',
        marginTop: '10px'
    },
    startButtonLoading: {
        marginLeft: '26px',
        marginRight: '26px',
        color: '#000000'
    },
    addActivityButton: {
        marginTop: '10px'
    },
    addActivityButtonLoading: {
        marginLeft: '50px',
        marginRight: '50px'
    },

}));

export default function ActivityDetails({activityFromProps}) {

    const [activity, setActivity] = useState({})
    const [isFetching, setIsFetching] = useState(true)
    const [isFetchingMagicLink, setIsFetchingMagicLink] = useState(false)
    const [isAddingActivity] = useState(false)
    // const [isActivityAdded, setIsActivityAdded] = useState(false)

    const classes = useStyles();
    const {timelineUuid, activityId} = useParams();

    useEffect(() => {
        if (activityFromProps) {
            setActivity(activityFromProps)
        } else {
            setIsFetching(true)
            // enroll(activityId, timelineUuid)
            //     .then(data => {
            //         return fetchActivity(activityId)
            //     }).then(fetchedActivity => {
            //     setActivity(fetchedActivity.activity)
            //     setIsFetching(false)
            // })
            fetchActivity(activityId).then(fetchedActivity => {
                setActivity(fetchedActivity.activity)
                setIsFetching(false)
            })
        }

    }, [timelineUuid, activityId, activityFromProps])

    const startNowClicked = () => {
        setIsFetchingMagicLink(true)

        fetchUser().then(fetchedUser => {
            return fetchMagicLink(activityId, fetchedUser.user.email)
        }).then(data => {
            if (data && data.url) {
                window.location = data.url
            }
        }).catch(error => {
            setIsFetchingMagicLink(false)
        })
    }

    // const addToMyActivitiesClicked = () => {
    //     setIsAddingActivity(true)
    //     addActivity(activityId).then((data) => {
    //         setIsAddingActivity(false)
    //         setIsActivityAdded(true)
    //     })
    // }

    let skills = []
    if (activity && activity.achievements && Array.isArray(activity.achievements)) {
        skills = activity.achievements.map(achievement => {
            return (
                <Chip key={achievement.id}
                    icon={<SchoolIcon/>}
                    label={achievement.name}
                    className={classes.skillChips}
                    color="primary"
                />
            )
        })
    }

    const renderStartButton = (isFetching, isStarted, isLocked) => {
        let text = 'Start Now'
        if (isStarted) {
            text = 'Continue'
        }

        if (isLocked) {
            return <Button className={classes.startButton} variant="contained" color="primary" endIcon={<LockIcon/>}>Locked</Button>
        }

        if (isFetching) {
            return <Button className={classes.startButton} variant="contained" color="primary"><CircularProgress className={classes.startButtonLoading} size="25px"/></Button>
        } else {
            return <Button className={classes.startButton} variant="contained" color="primary" onClick={startNowClicked}>{text}</Button>
        }
    }

    const renderAddActivityButton = (isFetching, isStarted) => {
        // if (isFetching) {
        //     return <Button className={classes.addActivityButton} variant="outlined" color="primary"><CircularProgress className={classes.addActivityButtonLoading} size="25px"/></Button>
        // }
        // if (isActivityAdded) {
        //     return <Button className={classes.addActivityButton} variant="outlined" color="primary" >Activity Added&nbsp;<DoneAllIcon/></Button>
        // }
        // if (!isStarted) {
        //     return <Button className={classes.addActivityButton} variant="outlined" color="primary" onClick={addToMyActivitiesClicked} >Add to my Activities</Button>
        // }
        return ''
    }

    let leadImage = activity.leadImage ? activity.leadImage : 'https://source.unsplash.com/1600x900/?business&sig=' + activity.id;

    return (
        <React.Fragment>
            {isFetching ? <Grid container direction="row" justify="center" alignItems="center" spacing={2}><Loading/></Grid> :
            <div>
                <div className={classes.heroImage} style={{backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.75)), url(' + leadImage + ')'}}>
                    <div className={classes.heroText}>
                        <Typography variant="h3">{activity.name}</Typography>
                        {renderStartButton(isFetchingMagicLink, activity.started, activity.isLocked)}
                        {renderAddActivityButton(isAddingActivity, activity.started)}
                    </div>
                </div>
                <Container>
                    <Grid
                        container
                        direction="row"
                        spacing={4}
                        style={{marginTop: '20px'}}
                    >
                        <Grid item md={3}>
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="h6">Description</Typography>
                            <br/>
                            <div dangerouslySetInnerHTML={{__html:activity.description}}></div>
                            <br/>
                            <br/>
                            <br/>
                            {activity.instructions ? <Typography variant="h6">Instructions</Typography> : ''}
                            <br/>
                            <div dangerouslySetInnerHTML={{__html:activity.instructions}}></div>
                        </Grid>
                        <Grid item md={1}>
                        </Grid>
                        <Grid item md={2}>
                            {skills && skills.length ? <Typography variant="h6">Skills</Typography> : ''}
                            {skills}
                        </Grid>
                    </Grid>
                </Container>
                <BackButton/>
            </div>
            }
        </React.Fragment>
    );
}
