import axios from "axios";

const SURVEY_ID = 'd5373078-be74-4fa4-8352-d62ee99fa94d'
const BASE_URL = 'https://hczom1xb31.execute-api.us-east-1.amazonaws.com/dev'

const skillpacaApi = () => {

    const getJwt = () => {
        return localStorage.getItem('token')
    }

    const fetchParticipantResponse = async () => {
        return axios.get(BASE_URL + '/practera/response/' + SURVEY_ID, {
            headers: {
                Authorization: "Bearer " + getJwt()
            }
        })
        .then(data => {
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
    }

    const fetchSurvey = async () => {
        return axios.get(BASE_URL + '/survey/' + SURVEY_ID, {
            headers: {
                Authorization: "Bearer " + getJwt()
            }
        })
            .then(data => {
                return data.data;
            })
            .catch(error => {
                console.error("Error in Axios call to 21skills backend", error)
            })
    }

    return {
        fetchParticipantResponse,
        fetchSurvey,
        SURVEY_ID
    }
}

export default skillpacaApi()
