import React, {useEffect, useState} from "react";
import JourneyCard from "./JourneyCard";
import skillpacaApi from "../../utils/skillpacaApi";
import Grid from "@material-ui/core/Grid";
import Loading from "../util/Loading";
import {fetchActivities, fetchEvents, fetchUser} from "../../utils/practeraApi";
import ErrorComponent from "../errorHandling/ErrorComponent";


export default function ParticipantJourney({openStep}) {

    const [response, setResponse] = useState({});
    const [activities, setActivities] = useState([])
    const [events, setEvents] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        Promise.all([fetchUser(), fetchActivities(), fetchEvents(), skillpacaApi.fetchParticipantResponse()]).then(([userResponse, activitiesResponse, eventsResponse, surveyResponse]) => {
            setUser(userResponse.user)
            setActivities(activitiesResponse.activities)
            setEvents(eventsResponse.events)
            setResponse(surveyResponse)
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
            setIsError(true)
        })
    }, [openStep])

    const getResponseStatus = (response) => {
        if (response && response.status === "COMPLETED") {
            return "DONE"
        } else if (response) {
            return "IN_PROGRESS"
        } else {
            return "NOT_STARTED"
        }
    }

    const getActivityStatus = (activityId) => {
        activities.forEach(activity => {
            if (activity.id === activityId) {
                if (activity.progress >= 100) {
                    return "DONE"
                } else if (activity.progress > 0) {
                    return "IN_PROGRESS"
                }
            }
        })
        return "NOT_STARTED"
    }

    const getEventStatus = () => {
        let isBooked = false
        events.forEach(event => {
            if (event.isBooked) {
                isBooked = true
            }
        })
        if(isBooked) {
            return "DONE"
        } else {
            return "NOT_STARTED"
        }
    }

    let participantJourney = [
            {
                platform: 'skillpaca',
                title: "Welcome & Introduction",
                subtitle: 'Start Here',
                imageUrl: 'https://images.pexels.com/photos/3719037/pexels-photo-3719037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                activities: [
                    {
                        title: 'Register for one of the 3 welcome events',
                        type: 'event',
                        status: getEventStatus(),
                        eventId: '33163',
                        id: '33163'
                    },
                    {
                        title: 'Find your passion',
                        type: 'link',
                        id: skillpacaApi.SURVEY_ID,
                        url: 'https://survey.21skills.com/practera/xprize/' + skillpacaApi.SURVEY_ID + '?jwt='+localStorage.getItem('token'),
                        status: getResponseStatus(response)
                    }
                ]
            },
            {
                platform: 'practera',
                title: "Foundational Skill Boosters",
                subtitle: 'Journey',
                imageUrl: 'https://images.unsplash.com/photo-1476231790875-016a80c274f3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                activities: [
                    {
                        title: 'Understanding Your Product',
                        type: 'activity',
                        activityId: '10700',
                        id: '10700',
                        status: getActivityStatus('10700')
                    },
                    {
                        title: 'Understanding Your Customer',
                        type: 'activity',
                        activityId: '10701',
                        id: '10701',
                        status: getActivityStatus('10701')
                    },
                    {
                        title: 'Building Great Customer Relationships',
                        type: 'activity',
                        activityId: '10702',
                        id: '10702',
                        status: getActivityStatus('10702')
                    },
                    {
                        title: 'Listening',
                        type: 'activity',
                        activityId: '10703',
                        id: '10703',
                        status: getActivityStatus('10703')
                    },
                    {
                        title: 'Customer Service & Support',
                        type: 'activity',
                        activityId: '10704',
                        id: '10704',
                        status: getActivityStatus('10704')
                    },
                    {
                        title: 'Talking Benefits',
                        type: 'activity',
                        activityId: '10705',
                        id: '10705',
                        status: getActivityStatus('10705')
                    },
                    {
                        title: 'Following Up on Leads',
                        type: 'activity',
                        activityId: '10683',
                        id: '10683',
                        status: getActivityStatus('10683')
                    },
                    {
                        title: 'Quotes & Contracts',
                        type: 'activity',
                        activityId: '10706',
                        id: '10706',
                        status: getActivityStatus('10706')
                    },
                    {
                        title: 'Presentations & Proposals',
                        type: 'activity',
                        activityId: '10707',
                        id: '10707',
                        status: getActivityStatus('10707')
                    },
                    {
                        title: 'Sales Administration & Customer Records',
                        type: 'activity',
                        activityId: '10708',
                        id: '10708',
                        status: getActivityStatus('10708')
                    }
                ]
            }
        ]

    function renderJourney() {
        return participantJourney.map((step) => {
            return <JourneyCard key={step.title} step={step} openStep={openStep} user={user}/>
        })
    }

    function renderLoading(isLoading) {
        if (isLoading) {
            return (
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Loading/>
                </Grid>
            )
        } else {
            return ''
        }
    }

    return (
        <React.Fragment>
            {renderLoading(isLoading)}
            {isError ? <ErrorComponent message="An error occurred while loading the journey"/> : ''}
            <div>
                {!isLoading && !isError ? renderJourney() : ''}
            </div>
        </React.Fragment>
    )
}
