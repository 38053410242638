import React from 'react';
import loading from '../../static/loading.svg'

export default function Loading({width}) {

    const containerStyle = {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center'
    }

    const style = {
        position: 'relative',
        textAlign: 'center',
    }

    return (
        <div style={containerStyle}>
            <div style={style}>
                <img src={loading} alt="loading" width={width || "75px"}/>
            </div>
        </div>
    );
}
