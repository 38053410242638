import React from "react";
import {Typography} from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from "@material-ui/core/Button";

export default function Terms() {

    return (
        <React.Fragment>
            <Typography variant='h4'>Privacy Policy and Terms of Use</Typography>
            <br/>
            <Typography>Below are our terms and conditions. If you have any questions about your data and privacy or if you want to discontinue and have all your data removed, feel free to reach out anytime to <strong>employ@practera.com</strong></Typography>
            <br/>
            <Typography>Your participation in Practera Employ is in conjunction with the XPRIZE Rapid Reskilling Competition. Evaluation of our team’s performance in the competition relies on the collection and sharing of your anonymized data. If you agree to the terms of use of this site, the following data will be tracked and shared with XPRIZE: training start date and training end date.</Typography>
            <br/>
            <Typography>Here’s the high-level summary of what the full document basically says in legal terms:
                <ul>
                    <li>Your data is your data, our data is our data.</li>
                    <li>We protect your data and only use it to deliver and improve the service.</li>
                    <li>People who need to see your data (mentors, educators, customer support) to deliver and improve the service are allowed to.</li>
                    <li>We don’t give away or sell your data. When researchers we’re working with look at data, it’s anonymised.</li>
                    <li>Pretend your grandma is reviewing everything you put on our site - be nice and professional.</li>
                    <li>Don’t hack us.</li>
                </ul>
            </Typography>
            <br/>
            <Typography>If you don’t want us to capture any of your data, please feel free to inform us that you want to opt out of this program.</Typography>
            <Button href="mailto:employ@practera.com" variant="contained" color="primary">Opt out</Button>
            <br/>
            <br/>
            <Typography><span><a href="http://practera.com/wp-content/uploads/practera_terms_conditions.pdf" target="_blank" rel="noreferrer">Privacy Policy and Terms of Use <OpenInNewIcon style={{verticalAlign: 'bottom'}}/></a></span></Typography>
        </React.Fragment>
    )
}
