import React from 'react';
import {Divider, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CalendarEvent from "./CalendarEvent";
import {useEffect, useState} from "react";
import {fetchEvents} from "../../utils/practeraApi";
import LoadingSmall from "../util/LoadingSmall";
import ErrorComponent from "../errorHandling/ErrorComponent";

export default function Calendar() {

    const [events, setEvents] = useState([])
    const [isFetching, setIsFetching] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        fetchEvents().then(fetchedData => {
            setEvents(fetchedData.events);
            setIsFetching(false);
        }).catch(error => {
            setIsFetching(false)
            setIsError(true)
        })
    }, [])

    const eventCards = events.map(event => {
        return (
            <React.Fragment key={event.id}>
                <CalendarEvent event={event}/>
                <Divider/>
            </React.Fragment>
            )
    })


    return (
        <div style={{ backgroundColor: '#f9f9f9', height:'100%', paddingTop: 24}}>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item sm={12}>
                    <Typography variant='h6' style={{textAlign: 'center'}}>Community Events</Typography>
                </Grid>
                <Grid item sm={12}>
                    {isFetching ? <LoadingSmall width={40}/> : eventCards}
                    {isError ? <ErrorComponent message="An error occurred while loading the events" /> : ''}
                </Grid>
            </Grid>
        </div>
    )
}
