import React, {useEffect, useState} from 'react';
import Hidden from "@material-ui/core/Hidden";
import {Link, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Checkbox, Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Input,
    InputLabel
} from "@material-ui/core";
import {register, verifyRegistration} from "../../utils/practeraApi";
import Loading from "../util/Loading";
import LoadingSmall from "../util/LoadingSmall";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 50
    },
    banner: {
        position:'fixed',
        width: '100%',
        zIndex: 1201,
        textAlign:'center',
        backgroundColor: 'lavender',
        height: 40,
        fontWeight: 700,
        lineHeight: '40px'
    },
    heroImage: {
        width: '100%',
        height: '70vh',
        background:"url('https://assets.mailtrap.io/packs/assets/landing/login-fb2fcb15feb36d8e9356.svg')",
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center'
    },
    logo: {
        width: '100%',
        height: '20%',
        background:"url('https://s3.amazonaws.com/assets.skillpaca.com/practera-employ/LogoFull.png')",
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center'
    }
}));

export default function Registration() {
    const classes = useStyles();

    const [isFetching, setIsFetching] = useState(true);
    const [userId, setUserId] = useState(null);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const [isBadPassword, setIsBadPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [debounceId, setDebounceId] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const [isBadPasswordClient, setIsBadPasswordClient] = useState(false)
    const [debounceIdBadPasswordClient, setDebounceIdBadPasswordClient] = useState(null);

    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)

        verifyRegistration(params.get('email'), params.get('key')).then(data => {
            setUserId(data.User.id)
            setIsFetching(false)
        }).catch(error => {
            // window.location = '/'
            setIsFetching(false)
        })

    },[location.search])

    function registerUser() {
        setIsSaving(true)
        const params = new URLSearchParams(location.search)
        register(userId, password, params.get('key')).then(data => {
            console.log(data)
            window.location = '/?jwt=' + data.apikey
            //redirect to the journey page with data.jwt as the jwt param
        }).catch((error) => {
            setIsSaving(false)
            setPassword('')
            setConfirmPassword('')
            setIsBadPassword(true)
            //handle error when password sucks
        })
    }

    function updatePassword(value) {
        setPassword(value)
        setIsBadPassword(false)
        validateGoodPasswordClient(value)
        validateMatching(value, confirmPassword)
    }

    function updateConfirmPassword(value) {
        setConfirmPassword(value)
        validateMatching(password, value)
    }

    function validateMatching(passwordOne, passwordTwo) {
        if (debounceId) {
            clearTimeout(debounceId)
        }
        setDebounceId(setTimeout(() => {
            if (passwordOne === '' || passwordTwo === '') {
                setPasswordsMatch(true)
            } else {
                setPasswordsMatch(passwordOne === passwordTwo)
            }
        }, 500))
    }

    function validateGoodPasswordClient(password) {
        if (debounceIdBadPasswordClient) {
            clearTimeout(debounceIdBadPasswordClient)
        }
        setDebounceIdBadPasswordClient(setTimeout(() => {
            if (password === '') {
                setIsBadPasswordClient(false)
            } else {
                const containsNumber = /\d/g;
                const containsLetters = /\w/g;
                setIsBadPasswordClient(!(containsLetters.test(password) && containsNumber.test(password) && password.length > 7))
            }
        }, 500))
    }

    function handleCheckTermsAndConditions() {
        setIsChecked(!isChecked)
    }

    return (
        <React.Fragment>
            {isFetching ? <Loading/> :
                <React.Fragment>
                    <Container>
                        <div className={classes.root}>
                            <Grid container direction={"row"} spacing={3}>
                                <Hidden xsDown>
                                    <Grid item sm={6}>
                                        <div className={classes.heroImage}/>
                                    </Grid>
                                </Hidden>
                                <Grid item sm={6} xs={12} style={{height: '100%', padding: 30}}>
                                    <div className={classes.logo}/>
                                    <div style={{position: 'relative', top: '20%'}}>
                                        <FormControl>
                                            <InputLabel htmlFor="my-input">Create Password</InputLabel>
                                            <Input type="password" id="my-input" error={isBadPassword || isBadPasswordClient} aria-describedby="my-helper-text" onChange={(e) => updatePassword(e.target.value)} value={password} />
                                            <FormHelperText id="my-helper-text" error={isBadPasswordClient}>Use at least 8 characters, at least one letter and at least one number</FormHelperText>
                                            {isBadPassword ? <FormHelperText error id="my-helper-text">Your password is not secure, please choose a better password</FormHelperText>: ''}
                                        </FormControl>
                                        <br/>
                                        <br/>
                                        <FormControl>
                                            <InputLabel htmlFor="my-input">Confirm Password</InputLabel>
                                            <Input type="password" helperText="No!" id="my-input" aria-describedby="my-helper-text" error={!passwordsMatch} onChange={(e) => updateConfirmPassword(e.target.value)} value={confirmPassword} />
                                            {!passwordsMatch ? <FormHelperText error id="my-helper-text">Your passwords do not match</FormHelperText>: ''}
                                        </FormControl>
                                        <br/>
                                        <br/>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleCheckTermsAndConditions}
                                                    name="termsAndConditions"
                                                    color="primary"
                                                />
                                            }
                                            label={<span>Accept the <a href='https://images.practera.com/terms_and_conditions/practera_terms_conditions.pdf' target='_blank' rel="noreferrer">terms and conditions</a></span>}
                                        />
                                        <br/>
                                        <br/>
                                        <FormControl>
                                            {isSaving ? <LoadingSmall width={40}/> : <Button disabled={!isChecked || !password || !confirmPassword || !passwordsMatch || isBadPasswordClient} variant='contained' color='primary' onClick={registerUser}>Submit</Button>}
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                    <Hidden xsDown>
                        <div className={classes.banner} style={{top: 0}}>Do you have any questions or need help? Send us an email to <a href="mailto:employ@practera.com">employ@practera.com</a></div>
                        <div className={classes.banner} style={{bottom: 0}}>Do you want to know what data we collect and see our privacy policy? <Link to={"/terms"}>Click here</Link> to learn more.</div>
                    </Hidden>
                </React.Fragment>
            }

        </React.Fragment>
    )
}
