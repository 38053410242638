import React, {useEffect, useState} from 'react';
import {Grid, LinearProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const END_DATE = new Date('2021-08-08');
const START_DATE = new Date('2021-05-10');
const _MS_PER_DAY = 1000 * 60 * 60 * 24;

const useStyles = makeStyles((theme) => ({
    progress: {
        height: 20,
        borderRadius: 10
    },
    daysLeft: {
        lineHeight: '20px',
        fontWeight: 700
    },
    container: {
        marginTop: 10
    }
}))

export default function JourneyProgress() {
    const classes = useStyles();

    const [currentProgress, setCurrentProgress] = useState(0);
    const [daysLeft, setDaysLeft] = useState(0);

    useEffect(() => {
        let percentage = 100 - (100 * ((END_DATE - Date.now()) / (END_DATE - START_DATE)));
        if (percentage > 100) {
            percentage = 100
        }

        let daysLeft = dateDiffInDays(new Date(), END_DATE);
        if (daysLeft < 0) {
            daysLeft = 0;
        }

        setDaysLeft(daysLeft)
        setCurrentProgress(percentage);
    }, [])


    function dateDiffInDays(a, b) {
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    return (
        <Grid className={classes.container} container direction="row" spacing={3}>
            <Grid item sm={2}>
            </Grid>
            <Grid item sm={6} xs={7}>
                <LinearProgress className={classes.progress} variant="determinate" value={currentProgress} />
            </Grid>
            <Grid item sm={4} xs={4}>
                <span className={classes.daysLeft}>{daysLeft} days left</span>
            </Grid>
        </Grid>
    )
}
