import React, {useState} from 'react';
import {ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import LoadingSmall from "../util/LoadingSmall";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import List from "@material-ui/core/List";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LockIcon from "@material-ui/icons/Lock";
import {fetchMagicLink} from "../../utils/practeraApi";
import {useNavigate} from "react-router-dom";

export default function JourneyStep({item, user}) {

    const navigate = useNavigate()

    const [isFetching, setIsFetching] = useState(false)

    function getStatusIcon(status) {
        switch (status) {
            case 'NOT_STARTED':
                return <RadioButtonUncheckedIcon/>
            case 'IN_PROGRESS':
                return <PauseCircleOutlineIcon/>
            case 'DONE':
                return <CheckCircleIcon style={{color: 'green'}} />
            case 'LOCKED':
                return <LockIcon/>
            default:
                return <RadioButtonUncheckedIcon/>
        }
    }

    function clickListItem(item, e) {
        if (!isFetching) {
            setIsFetching(true)
            switch (item.type) {
                case 'event':
                    bookEvent(item)
                    navigate('/events')
                    break;
                case 'activity':
                    launchActivity(item)
                    break;
                case 'link':
                    window.location = item.url
                    break;
                default:
                    break;
            }
        }
    }

    function bookEvent(item) {

    }

    function launchActivity(item) {
        setIsFetching(true)
        fetchMagicLink(item.activityId, user.email).then((data) => {
            window.location = data.url
        })
    }

    return (
        <List>
            <ListItem button={item.status !== 'LOCKED' && !isFetching} key={item.id} role={undefined} dense onClick={(e) => clickListItem(item, e)}>
                <ListItemIcon>
                    {isFetching ? <LoadingSmall width={25}/> : getStatusIcon(item.status)}
                </ListItemIcon>
                <ListItemText primary={item.title} />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                        <ArrowForwardIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    )
}
