import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto',
            'Questrial, sans-serif',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: "#2BC1D9",
        },
        secondary: {
            main: "#adf82d",
        },
        background: {
            default: "#ffffff"
        }
    },
    custom: {
        drawerColor: "#F5F6FA"
    }
});
