import React from 'react';
import {Button, Typography} from "@material-ui/core";

export default function FailedLogin() {

    const containerStyle = {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center'
    }

    const style = {
        position: 'relative',
        textAlign: 'center',
    }

    const buttonStyle = {
        color: 'white'
    }

    return (
        <div style={containerStyle}>
            <div style={style}>
                <Typography>Sorry, something went wrong when logging you in.</Typography>
                <Typography><small>{new Date().toISOString()}</small></Typography>
                <Button variant="contained" color="primary" style={buttonStyle} onClick={() => window.location = "https://login-app.sandbox.p2.practera.com/login"}>Click here to try again</Button>
            </div>
        </div>
    );
}
