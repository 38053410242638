import React, {useEffect, useState} from "react";
import Loading from "../util/Loading";
import {fetchMagicLink, fetchUser} from "../../utils/practeraApi";

export default function MyProfile() {

    const [frameSrc, setFrameSrc] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchUser().then(userResponse => {
            console.log(userResponse)
            return fetchMagicLink(null, userResponse.user.email)
        }).then(data => {
            setFrameSrc(data.url)
            setIsLoading(false)
        })
    }, [])

    return (
        <React.Fragment>
            {isLoading ? <Loading/> :
                <div style={{height: '100vh', width: '100%'}}>
                    <iframe title="My Profile" style={{height: '100%', width: '100%'}} src={frameSrc} frameBorder='none'></iframe>
                </div>
            }
        </React.Fragment>
    )
}
