import React from "react";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import {Typography} from "@material-ui/core";

export default function ErrorComponent({message}) {
    return (
        <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item lg={4}/>
            <Grid item lg={4}>
                <Alert variant="outlined" severity="error">
                    <Typography variant='body1'>{message ? message : 'An error occurred!'}</Typography>
                    <Typography variant='body2'>{new Date().toISOString()}</Typography>
                </Alert>
            </Grid>
            <Grid item lg={4}/>
        </Grid>
    )
}
