import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useNavigate} from "react-router-dom";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    root: {
        position: "absolute",
        top: "8px",
        left: "16px",
        fontSize: "18px",
        color: "#FFFFFF",
    }
});

export default function BackButton () {
    const classes = useStyles();

    const navigate = useNavigate();

    return (
        <Button onClick={() => navigate(-1)} className={classes.root} color="primary">
            <ArrowBackIcon/> Back
        </Button>
    )
}
