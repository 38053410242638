import {Button, Collapse} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import React, {useState} from "react";
import ParticipantJourney from "./ParticipantJourney";
import JourneyProgress from "./JourneyProgress";


export default function JourneyContainer({setCalendarOpen}) {

    const [contentOpen, setContentOpen] = useState(false)
    const [frameSrc, setFrameSrc] = useState('')

    function openStep(url) {
        setContentOpen(true)
        setCalendarOpen(false)
        setFrameSrc(url);
    }

    function closeStep() {
        setContentOpen(false)
        setCalendarOpen(true)
        setFrameSrc('');
    }

    return (
        <React.Fragment>
            <Collapse in={contentOpen}>
                <Button onClick={closeStep}><ArrowBackIcon/> Back to journey</Button>
                <div style={{height: '100vh', width: '100%'}}>
                    <iframe title="Learning Content" style={{height: '100%', width: '100%'}} src={frameSrc} frameBorder='none'></iframe>
                </div>
            </Collapse>
            <Collapse in={!contentOpen}>
                <Grid container>
                    <Grid item xs={12}>
                        <JourneyProgress/>
                    </Grid>
                    <Grid item xs={12}>
                        <ParticipantJourney openStep={openStep}/>
                    </Grid>
                </Grid>
            </Collapse>
        </React.Fragment>
    )
}
