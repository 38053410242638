import React, {useEffect, useState} from 'react';
import ActivityCard from "./ActivityCard";
import {queryActivities} from "../../utils/practeraApi";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Loading from "../util/Loading";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Link} from "react-router-dom";
import {Hidden} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorComponent from "../errorHandling/ErrorComponent";

export default function Activities({isStarted}) {

    const [isFetching, setIsFetching] = useState(true)
    const [activities, setActivities] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [existingQuery, setExistingQuery] = useState('')
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        setIsFetching(true)
        queryActivities('', isStarted).then(payload => {
            console.log('Activities', payload.activities)
            setActivities(payload.activities)
            setIsFetching(false)
        }).catch(error => {
            setIsFetching(false)
            // setIsFetching(true)
            setIsError(true)
        })

    },[isStarted])

    let activityCards = ''

    if (activities && Array.isArray(activities)) {
        activityCards = activities.map((activity) => {
            return (
                <Grid key={activity.id} item xl={3}>
                    <ActivityCard activity={activity}/>
                </Grid>
            )
        })
    }

    if (activityCards.length === 0) {
        activityCards = <Typography variant="h6">No results</Typography>
    }

    const searchActivities = (event) => {
        event.preventDefault()
        setIsFetching(true)
        setExistingQuery(searchQuery)
        setSearchQuery('')
        queryActivities(searchQuery, isStarted).then(payload => {
            setActivities(payload.activities)
            setIsFetching(false)
        }).catch(error => {
            setIsFetching(false)
            setIsError(true)
        })
    }

    const clearSearch = () => {
        setIsFetching(true)
        setExistingQuery(searchQuery)
        setSearchQuery('')
        queryActivities(null, isStarted).then(payload => {
            setActivities(payload.activities)
            setIsFetching(false)
        }).catch(error => {
            setIsFetching(false)
            setIsError(true)
        })
    }

    const renderError = (isError) => {
        if (isError) {
            return (
                <ErrorComponent message="An error occurred while loading the activities!"/>
            )
        } else {
            return ''
        }
    }

    const browseLibrary = (isStarted) => {
        if (isStarted) {
            return (
                <Grid item lg={4}>
                    <Button component={Link} to={"library"} variant="outlined" color="primary" endIcon={<ArrowForwardIcon/>}>Browse Library</Button>
                </Grid>
            )
        } else {
            return ''
        }
    }

    const renderLoading = (isLoading) => {
        if (isLoading) {
            return (
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Loading/>
                </Grid>
            )
        } else {
            return ''
        }
    }

    const renderSearch = (isStarted) => {
        if (isStarted === null) {
            return (
                <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <form noValidate autoComplete="off" style={{margin: "20px"}} onSubmit={searchActivities}>
                            <TextField InputProps={{startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )}} placeholder="What do you want to learn?" variant="outlined" style={{width: "100%", background: '#ffffff'}} value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
                            {existingQuery ? <p>Results for: <strong>{existingQuery}</strong><span><CancelIcon onClick={clearSearch} fontSize="small" style={{marginLeft: '5', verticalAlign:'bottom', cursor: 'pointer'}}/></span></p> : ''}
                        </form>
                    </Grid>
                </Grid>
            )
        } else {
            return ''
        }
    }

    return (
        <Container>
            <Hidden smDown>
                {renderSearch(isStarted)}
                {renderLoading(isFetching)}
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    {renderError(isError)}
                    {!isFetching ?
                        <React.Fragment>
                            {activityCards}
                            {browseLibrary(isStarted)}
                        </React.Fragment>
                    : ''}
                </Grid>
            </Hidden>

            <Hidden mdUp>
                <div style={{marginTop: 40}}>
                    {renderSearch(isStarted)}
                    {renderLoading(isFetching)}
                    <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                        {renderError(isError)}
                        {!isFetching ?
                            <React.Fragment>
                                {activityCards}
                                {browseLibrary(isStarted)}
                            </React.Fragment>
                            : ''}
                    </Grid>
                </div>
            </Hidden>

        </Container>
    );
}
