import {Button, Typography} from "@material-ui/core";
import React from "react";

export default function NotFound() {
    const containerStyle = {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center'
    }

    const style = {
        position: 'relative',
        textAlign: 'center',
    }

    const buttonStyle = {
        color: 'white'
    }

    return (
        <div style={containerStyle}>
            <div style={style}>
                <Typography>404</Typography>
                <Typography>Could not find the page you are looking for.</Typography>
                <Typography><small>{new Date().toISOString()}</small></Typography>
                <Button href="/" variant="contained" color="primary" style={buttonStyle}>Go Home</Button>
            </div>
        </div>
    );

}
