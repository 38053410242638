import axios from "axios";
import jsonwebtoken from "jsonwebtoken";

const dotenv = require('dotenv');
const path = require('path');
const ENV_FILE = path.join(__dirname, '.env');
dotenv.config({ path: ENV_FILE });

const APP_KEY= process.env.REACT_APP_APP_KEY
const GRAPHQL_URL= process.env.REACT_APP_GRAPHQL_URL
const LTI_URL= process.env.REACT_APP_LTI_URL
const PUBLIC_KEY=process.env.REACT_APP_PUBLIC_KEY
const GLOBAL_LOGIN_PUBLIC_KEY=process.env.REACT_APP_GLOBAL_LOGIN_PUBLIC_KEY

const PAGE_SIZE = 50;

const getJwt = () => {
    return localStorage.getItem('token')
}

export const fetchActivities = async () => {
    return await fetchActivitiesByQuery()
}

export const queryActivities = async (query, isStarted) => {
    return await fetchActivitiesByQuery(query, null, null, isStarted)
}

export const fetchActivity = async (activityId) => {
    return await fetchActivityById(activityId)
}

const fetchActivitiesByQuery = async (query = '', cursor = null, direction = null, started = null) => {

    let filterString = '';
    if (query) {
        filterString = 'filter: ["' + query.replace(' ', '","') + '"],'
    }

    let startedString = '';
    if (started != null) {
        if (started) {
            startedString = 'started: true,';
        } else {
            startedString = 'started: false,';
        }
    }

    let paginationString = 'first: ' + PAGE_SIZE;
    if (cursor) {
        if (direction === 'after') {
            paginationString = 'first: ' + PAGE_SIZE + ', ' + direction + ': "' + cursor + '"';
        } else {
            paginationString = 'last: ' + PAGE_SIZE + ', ' + direction + ': "' + cursor + '"';
        }
    }

    const body = {
        query: '{ ' +
            'activitiesConnection(scope: "Institution", ' + filterString + startedString + paginationString + '){ ' +
            'edges{ ' +
            '   cursor ' +
            '   node{ ' +
            '       id ' +
            '       name ' +
            '       description ' +
            '       leadImage ' +
            '       timelineUuid ' +
            '       isLocked ' +
            '       progress ' +
            '       achievements(type: "certification"){ ' +
            '           id ' +
            '           name ' +
            '           badge ' +
            '       }' +
            '       tasks {' +
            '          id' +
            '          name' +
            '          type' +
            '       }' +
            '    } ' +
            '} pageInfo { ' +
            '   hasNextPage ' +
            '   hasPreviousPage ' +
            '} ' +
            '}}'
    };
    return formatConnection('activitiesConnection', await fetchGraphql(body));
};

export const fetchEvents = async () => {
    const body = {
        query: '{' +
            '  events {' +
            '    id' +
            '    name' +
            '    type' +
            '    description' +
            '    eventStart' +
            '    eventEnd' +
            '    isBooked' +
            '    canBook' +
            '    location' +
            '    singleBooking' +
            '    remainingCapacity' +
            '    videoConference {' +
            '      provider' +
            '      url' +
            '      meetingId' +
            '      password' +
            '    }' +
            '    activityId' +
            '    activityName' +
            '  }' +
            '}'
    };
    return await fetchGraphql(body);
}

export const fetchAchievements = async () => {
    const body = {
        query: '{achievements(type: "certification") {\n' +
            '  id' +
            '  name' +
            '  description' +
            '  badge' +
            '  progress' +
            '}}'
    };
    return await fetchGraphql(body);
}

export const bookEvent = async (eventId) => {
    const url = 'https://admin.lf.practera.com/api/book_events.json';

    const body = {
        eventId: eventId
    }

    const decodedJwt = await getDecodedJwt()

    return axios({
        method: 'post',
        url: url,
        data: body,
        headers: {
            apikey: getJwt(),
            appkey: APP_KEY,
            timelineID: decodedJwt.timeline_id,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.data && response.data.errors) {
            throw response.data.errors
        }
        return response.data.data;
    }).catch(error => {
        console.error('==================Practera API Error Response===================')
        console.error(JSON.stringify(error, null, 2));
        console.error('Query: ', body)
        console.error(getJwt())
        console.error('===========================================================')
        throw error;
    });
}

//https://admin.lf.practera.com/api/auths.json?action=verify_registration

export const verifyRegistration = async (email, key) => {
    const url = 'https://admin.lf.practera.com/api/auths.json?action=verify_registration';

    const body = {
        email: email,
        key: key
    }

    return axios({
        method: 'post',
        url: url,
        data: body,
        headers: {
            appkey: APP_KEY,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.data && response.data.errors) {
            throw response.data.errors
        }
        return response.data.data;
    }).catch(error => {
        console.error('==================Practera API Error Response===================')
        console.error(JSON.stringify(error, null, 2));
        console.error('Query: ', body)
        console.error('===========================================================')
        throw error;
    });
}

export const register = async (userId, password, key) => {
    const url = 'https://admin.lf.practera.com/api/auths.json?action=registration';

    const body = {
        userId: userId,
        password: password,
        key: key
    }

    return axios({
        method: 'post',
        url: url,
        data: body,
        headers: {
            appkey: APP_KEY,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.data && response.data.errors) {
            throw response.data.errors
        }
        return response.data.data;
    }).catch(error => {
        console.error('==================Practera API Error Response===================')
        console.error(JSON.stringify(error, null, 2));
        console.error('Query: ', body)
        console.error('===========================================================')
        throw error;
    });
}

export const addActivity = async (activityId) => {
    const body = {
        operationName: 'start',
        query: 'mutation start ($activityId: Int!) { startActivity(activityId: $activityId) }',
        variables: '{"activityId": ' + activityId + '}'
    };
    return await fetchGraphql(body);
};

const fetchActivityById = async (activityId) => {
    if (!activityId) {
        return;
    }
    const body = {
        query: '{activity(id: ' + activityId + '){id name started description leadImage instructions isLocked timelineUuid progress achievements(type: "certification"){ id name badge }} }' // tasks{id name type contextId status{ status }}}}'
    };
    return fetchGraphql(body);
};

export const fetchUser = async () => {
    const body = {
        query: '{user{id name email uuid}}'
    }
    return fetchGraphql(body);
}

const fetchGraphql = async (body) => {
    if (!getJwt()) {
        console.error('Error calling Practera\'s GraphQL endpoint. No JWT detected.');
        return false;
    }
    return axios({
        method: 'post',
        url: GRAPHQL_URL,
        data: body,
        headers: {
            apikey: getJwt(),
            appkey: APP_KEY,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.data && response.data.errors) {
            throw response.data.errors
        }
        return response.data.data;
    }).catch(error => {
        console.error('==================Graphql Error Response===================')
        console.error(JSON.stringify(error, null, 2));
        console.error('Query: ', body)
        console.error(getJwt())
        console.error('===========================================================')
        throw error;
    });
};

const formatConnection = (key, data) => {
    if (!data) return {};
    let resKey = key.replace('Connection', '');

    let result = { pageInfo: data[key].pageInfo };
    result[resKey] = [];

    for (const obj of data[key].edges) {
        if (!result.pageInfo.previousCursor) {
            result.pageInfo.previousCursor = obj.cursor;
        }
        result.pageInfo.nextCursor = obj.cursor;
        if (obj.node.progress) {
            obj.node.progress = 100 * obj.node.progress;
        } else {
            obj.node.progress = 0;
        }
        result[resKey].push(obj.node);
    }
    return result;
};

export const fetchMagicLink = async (activityId, email) => {
    return axios({
        method: 'post',
        url: LTI_URL + '/dev/magic',
        data: {activityId, email},
        headers: {
            Authorization: "Bearer " + getJwt(),
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.data && response.data.errors) {
            throw response.data.errors
        }
        return response.data;
    }).catch(error => {
        console.error('================== LearnFlow Error Response ===================')
        console.error(JSON.stringify(error, null, 2));
        console.error('===============================================================')
        throw error;
    });
}

export const enroll = async (activityId, timelineUuid) => {
    return axios({
        method: 'post',
        url: LTI_URL + '/dev/enroll',
        data: {activityId, timelineUuid},
        headers: {
            Authorization: "Bearer " + getJwt(),
            appkey: APP_KEY,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.data && response.data.errors) {
            throw response.data.errors
        } else {
            localStorage.setItem('token', response.data.jwt)
        }
        return response.data;
    }).catch(error => {
        console.error('================== LearnFlow Error Response ===================')
        console.error(JSON.stringify(error, null, 2));
        console.error('===============================================================')
        throw error;
    });
}

export const fetchLTIJwt = async (globalLoginJwt) => {
    return axios({
        method: 'post',
        url: LTI_URL + '/dev/jwt',
        data: {globalLoginJwt},
        headers: {
            Authorization: "Bearer " + globalLoginJwt,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response.data && response.data.errors) {
            throw response.data.errors
        }
        return response.data;
    }).catch(error => {
        console.error('================== LearnFlow Error Response ===================')
        console.error(JSON.stringify(error, null, 2));
        console.error('===============================================================')
        throw error;
    });
}

export const isValidJwt = async (jwt) => {
    if (!jwt) {
        return false;
    }
    return new Promise((resolve, reject) => {
        jsonwebtoken.verify(jwt, PUBLIC_KEY, { algorithms: ['RS256'] },
            (err, payload) => {
                if (err) {
                    console.warn('JWT is not valid', err.message);
                    resolve(false);
                }
                resolve(true);
            }
        );
    });
};

export const getDecodedJwt = async () => {
    const jwt = getJwt();
    if (!jwt) {
        return {};
    }
    return new Promise((resolve, reject) => {
        jsonwebtoken.verify(jwt, PUBLIC_KEY, { algorithms: ['RS256'] },
            (err, payload) => {
                if (err) {
                    console.warn('JWT is not valid', err.message);
                    resolve({});
                }
                resolve(payload);
            }
        );
    });
};

export const getDecodedGlobalLoginJwt = async () => {
    const jwt = localStorage.getItem('apikey');
    if (!jwt) {
        return {};
    }
    return new Promise((resolve, reject) => {
        jsonwebtoken.verify(jwt, GLOBAL_LOGIN_PUBLIC_KEY, { algorithms: ['RS256'] },
            (err, payload) => {
                if (err) {
                    console.warn('JWT is not valid', err.message);
                    resolve({});
                }
                resolve(payload);
            }
        );
    });
};



